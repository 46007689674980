import React from 'react';

import {WalletContext} from './WalletContext';

export function useWallet() {
  const context = React.useContext(WalletContext);

  if (!context || !context.walletManager) {
    throw new Error('No WalletProvider found in component tree.');
  }

  return context.walletManager;
}
