/* eslint-disable @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from 'react';
import type {ReactNode} from 'react';
import type {AssetList, Chain} from '@chain-registry/types';
import {WalletManager} from '@cosmos-kit/core';
import type {
  ChainName,
  EndpointOptions,
  MainWalletBase,
  MainWalletData,
  SessionOptions,
  SignerOptions,
  StorageOptions,
  ViewOptions,
  WalletName,
} from '@cosmos-kit/core';

import {WalletContext} from './WalletContext';

export interface WalletProviderProps {
  chains: Chain[];
  assetLists: AssetList[];
  wallets: MainWalletBase[];
  signerOptions?: SignerOptions;
  viewOptions?: ViewOptions;
  endpointOptions?: EndpointOptions;
  storageOptions?: StorageOptions;
  sessionOptions?: SessionOptions;
  children: ReactNode;
}

export function WalletProvider({
  chains,
  assetLists,
  wallets,
  signerOptions,
  viewOptions,
  endpointOptions,
  storageOptions,
  sessionOptions,
  children,
}: WalletProviderProps) {
  const walletManager = useMemo(
    () =>
      new WalletManager(
        chains,
        assetLists,
        wallets,
        signerOptions,
        viewOptions,
        endpointOptions,
        storageOptions,
        sessionOptions
      ),
    []
  );

  const [, setWalletData] = useState<MainWalletData>();
  const [, setWalletState] = useState(walletManager.state);
  const [, setWalletMsg] = useState<string | undefined>();
  const [, setWalletName] = useState<WalletName | undefined>(walletManager.currentWalletName);

  const [, setViewOpen] = useState<boolean>(false);
  const [, setChainName] = useState<ChainName | undefined>();
  const [, setQRUri] = useState<string | undefined>();

  walletManager.setActions({
    data: setWalletData,
    state: setWalletState,
    message: setWalletMsg,
    walletName: setWalletName,
    viewOpen: setViewOpen,
    chainName: setChainName,
    qrUri: setQRUri,
  });

  useEffect(() => {
    walletManager.onMounted();
    return () => {
      walletManager.onUnmounted();
    };
  }, []);

  return (
    <WalletContext.Provider
      value={{
        walletManager,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
}
